import React from "react";
import { Button, Image, Input, Popconfirm, Table, Typography } from "antd";
import EditProduction from "./EditProduction";
import { ShouldRender } from "src/components/ShouldRender";
import { IEvent, IEventProdDecor } from "src/types";
import moment from "moment";
import { TIME_FORMATS } from "src/constants";
import { updateSingleEvent } from "src/api-core/Events";
import { clonseJSON } from "src/utils/json";

interface Props {
  event?: IEvent;
  update: () => Promise<void>;
  loading: boolean;
}

export const ProductionTable = ({ event, update, loading }: Props) => {
  const { event_proddecor = [] } = event || {};
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState<number>(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleUpdate = (data: Partial<IEvent>) => {
    if (event) {
      const { _id, client_id, event_type, event_title, event_start_date } =
        event;
      const payload = {
        client_id,
        event_type,
        event_title,
        event_start_date,
        ...data,
      };
      // console.log("DATAa - ",data);
      
      return updateSingleEvent(_id, payload).then(update).then(handleClose);
    }
  };

  const handleSave = (values: IEventProdDecor) => {
    let updatedProduction = clonseJSON(event_proddecor);
    if (recordIndex === -1) {
      updatedProduction.push(values);
    } else {
      updatedProduction[recordIndex] = values;
    }
    const data = {
      event_proddecor: updatedProduction.map((item) => ({
        ...item,
        decor_img: [
          {
            file: item.decor_img[0].filename,
          },
        ],
      })) as any,
    };
    // console.log("data ", data);
    
    handleUpdate(data)?.then(update).then(handleClose);
  };

  const handleDelete = (index: number) => {
    const updatedProduction = clonseJSON(event_proddecor).filter(
      (_, i) => i !== index
    );
    const data = {
      event_proddecor: updatedProduction.map((item) => ({
        ...item,
        decor_img: [
          {
            file: item.decor_img[0].filename,
          },
        ],
      })) as any,
    };
    handleUpdate(data)?.then(update);
  };

  const defaultColumns = [
    {
      title: "Decor Title",
      dataIndex: "decor_title",
      key: "decor_title",
    },
    {
      title: "Decor Image",
      dataIndex: "decor_img",
      key: "decor_img",
      ellipsis: true,
      render: (_: any, record: IEventProdDecor) => (
        <div className="w-20">
          <Image
            alt="menu"
            rootClassName="event-image-preview"
            src={record.decor_img[0]?.file}
          />
        </div>
      ),
    },
    {
      title: "Decor Date",
      dataIndex: "decor_date",
      key: "decor_date",
      render: (_: any, record: IEventProdDecor) => {
        return moment(record.decor_date).format(TIME_FORMATS.DATE);
      },
    },
    {
      title: "Expected Decor Time",
      dataIndex: "expected_decor_time",
      key: "expected_decor_time",
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.decor_title)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.decor_date)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.expected_decor_time)
                .toLowerCase()
                .includes(value.toLowerCase())
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: IEventProdDecor) => {
        const index = event_proddecor.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(record)
        );
     
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        columns={defaultColumns}
        dataSource={event_proddecor}
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditProduction
          open={open}
          data={event_proddecor[recordIndex]}
          title={recordIndex === -1 ? "Add Production" : "Edit Production"}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
