export const Navigation = [
  {
    title: "OfferBanner",
    childrens: [
      {
        title: "List Of Offer Banner",
        path: "/admin/listofferbanner",
      },
      {
        title: " Create Offer Banner",
        path: "/admin/createofferbanner",
      },
    ],
  },
  {
    title: "Trivia",
    childrens: [
      {
        title: "List Of Trivia",
        path: "/admin/listoftrivia",
      },
      {
        title: " Create Trivia",
        path: "/admin/createtrivia",
      },
    ],
  },
  {
    title: "Events",
    path: "/admin/event",
  },
  // {
  //   "title": "Events",
  //   "childrens": [
  //     {
  //       "title": "All Events",
  //       "path": "/admin/listofallevents"
  //     },
  //     {
  //       "title": "Current Events",
  //       "path": "/admin/listofallsubevents"
  //     }
  //   ]
  // },
  {
    title: "Gallery",
    // "childrens": [
    //   {
    //  "title": "EventPhotos",
    path: "/admin/listofalleventphoto",
    //  },
    //   {
    //     "title": " Create EventPhoto",
    //     "path": "/admin/createeventphoto"
    //   }
    // ]
  },
  // {
  //   "title": "GHMS",
  //   "childrens": [
  //     {
  //       "title": "List Of Guestlist",
  //       "path": "/admin/listofguestlist"
  //     },
  //     {
  //       "title": "Create Guestlist",
  //       "path": "/admin/createguestlist"
  //     },
  //     {
  //       "title": "List Of Arrival Mgmnt",
  //       "path": "/admin/listofarrivalmgmnt"
  //     },
  //     {
  //       "title": "Create Arrival Mgmnt",
  //       "path": "/admin/createarrivalmgmnt"
  //     },
  //     {
  //       "title": "List Of Departure Mgmnt",
  //       "path": "/admin/listofdeparturemgmnt"
  //     },
  //     {
  //       "title": "Create Departure Mgmnt",
  //       "path": "/admin/createdeparturemgmnt"
  //     },
  //     {
  //       "title": "List Of Lost And Found",
  //       "path": "/admin/listoflostandfound"
  //     },
  //     {
  //       "title": "Create Lost And Found",
  //       "path": "/admin/createlostandfound"
  //     }
  //   ]
  // },
  {
    title: "Hotels",
    path: "/admin/hotels",
  },

  {
    title: "Blacklisted Vendors",
    path: "/admin/blacklisted",
  },
  // {
  //   "title": "Vendor",
  //   "childrens": [
  //     {
  //       "title": "List Of Vendors",
  //       "path": "/admin/listofvendors"
  //     },
  //     {
  //       "title": "List Of Vendor Car",
  //       "path": "/admin/listofvendorcar"
  //     },
  //     // {
  //     //   "title": "List Of Vendor Driver",
  //     //   "path": "/admin/listofvendordriver"
  //     // },
  //     {
  //       "title": "Create Vendor Food Bev",
  //       "path": "/admin/createvendorfoodbev"
  //     },
  //     // {
  //     //   "title": "Create Vendor Prod Decor",
  //     //   "path": "/admin/createvendorproddecor"
  //     // },
  //     // {
  //     //   "title": "Create Vendor Prod Decor Checklist",
  //     //   "path": "/admin/createvendorproddecorchecklist"
  //     // }
  //   ]
  // },
  {
    title: "Enquiry (list enquiries)",
    path: "/admin/listofenquiry",
  },
  {
    title: "Freelancer",
    childrens: [
      {
        title: "List Of Freelancer",
        path: "/admin/listoffreelancer",
      },
      {
        title: " Create Freelancer",
        path: "/admin/createfreelancer",
      },
      {
        title: "List Of Deployed Freelancer",
        path: "/admin/listoffreelancerassignedevent",
      },
      {
        title: "Create Deployed Freelancer",
        path: "/admin/createfreelancerassignedevent",
      },
    ],
  },
  {
    title: "Prioritisation",
    childrens: [
      {
        title: "List Of Prioritisation",
        path: "/admin/listofprioritisation",
      },
      {
        title: "Create Prioritisation",
        path: "/admin/createprioritisation",
      },
    ],
  },
  {
    title: "Client/Users",
    childrens: [
      {
        title: "List Of Users",
        path: "/admin/listofusers",
      },
      {
        title: "Create Client/User",
        path: "/admin/createusers",
      },
    ],
  },
  // {
  //   title: "Roles & Permissions",
  //   childrens: [
  //     {
  //       title: "List Of Roles",
  //       path: "/admin/listofroles",
  //     },
  //     {
  //       title: "List Of Permissions",
  //       path: "/admin/listofpermissions",
  //     },
  //     {
  //       title: "List Of PermissionRole",
  //       path: "/admin/listofpermissionrole",
  //     },
  //   ],
  // },
];
