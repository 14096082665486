import { DatePicker, Form, Input, Modal, Select } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import * as Yup from "yup";
import {
  GuestInvited,
  GuestOutstation,
  IGuestList,
  InvitationType,
} from "src/types";
import { Label } from "src/components/Label";
import moment from "moment";
import { TIME_FORMATS } from "src/constants";
import { ShouldRender } from "src/components/ShouldRender";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";

export type Guest = Omit<IGuestList, "_id" | "client_id" | "event_id">;

interface Props {
  handleClose: () => void;
  handleSave: (values: Guest) => void;
  guest?: Guest;
  open: boolean;
  title: string;
}

const validationSchema = Yup.object({
  guest_name: Yup.string().min(3, "Guest name must be at least 3 characters").required("Guest Name is required"),
  guest_email: Yup.string().email()  
    .test('tld', 'Invalid email. Supported TLD are .com/.in', function (value) {
    if (!value) return true; // Skip validation if the value is empty

    const tldRegex = /^(com|in)$/i; // Add more TLDs as needed

    const emailParts = value.split('@');
    const domainParts = emailParts[1] && emailParts[1].split('.');

    if (domainParts && domainParts.length > 1) {
      const tld = domainParts[domainParts.length - 1];
      return tldRegex.test(tld);
    }

    return true; // Validation will fail if the email format is incorrect
  }),
  guest_add: Yup.string().min(3,"Guest address must be at least 3 characters").required("Guest Address is required"),
  guest_mobile: Yup.number().test('len', 'Must be exactly 10 digits', val => val?.toString().length === 10).required("Guest Mobile is required"),
  guest_outstation: Yup.string().min(3,"Locality must be at least 3 characters").required("Locality is required"),
  // guest_invited: Yup.string().required("Guest Invited is required"),
  // guest_expected_nos: Yup.number().required(
  //   "Number Of Guest Expected is required"
  // ),
  // guest_invitation_type: Yup.string().required(
  //   "Guest Invitation Type is required"
  // ),
  guest_date_of_arrival: Yup.date().required("Guest Date Of Arrival required"),
});

const EditGuest = ({ handleClose, handleSave, guest, open, title }: Props) => {

  const initalValues = {
    guest_add: guest?.guest_add,
    guest_date_of_arrival: guest?.guest_date_of_arrival
      ? moment(guest.guest_date_of_arrival)
      : undefined,
    guest_email: guest?.guest_email,
    guest_expected_nos: guest?.guest_expected_nos,
    guest_invitation_type: guest?.guest_invitation_type,
    guest_invited: guest?.guest_invited,
    guest_mobile: guest?.guest_mobile,
    guest_name: guest?.guest_name,
    guest_outstation: guest?.guest_outstation,
  };
  // console.log("initalValues",initalValues);
  const [hideGuestInvited, setHideGuestInvited] = useState("");
  const [expectedNos, setExpectedNos] = useState<number>()

  useEffect(() => {
    if (guest?.guest_invited === GuestInvited.Individual) setHideGuestInvited("Individual")
    
  }, [guest?.guest_invited])

  const saveData = async(values: any) => {
    
    handleSave({
      guest_add: values.guest_add!,
      guest_date_of_arrival: values.guest_date_of_arrival!.format(
        TIME_FORMATS.API_TIME
      ),
      guest_email: values.guest_email!,
      guest_expected_nos: expectedNos!,
      guest_invitation_type: values.guest_invitation_type!,
      guest_invited: values.guest_invited,
      guest_mobile: values.guest_mobile!,
      guest_name: values.guest_name!,
      guest_outstation: values.guest_outstation!,
    })
  }
  

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={saveData}
    >


      {({ handleSubmit, values, setFieldValue, errors }) => {
      
        const handleSetHideGuestInvited = (e:any, value : any)=>{
          setHideGuestInvited(e)
          setFieldValue("guest_invited", value.value)
          if(value.value === "Individual") {
            setExpectedNos(1)
          }
        }
        const handleInput = (value:any)=>{
          if(values.guest_invited === "Family") {
            setExpectedNos(value.target.value)
          }
        }
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={()=>handleSubmit()}
          >
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Name" }}
                name="guest_name"
                placeholder="enter guest name"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: false, text: "Email" }}
                name="guest_email"
                placeholder="enter guest email"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Mobile" }}
                name="guest_mobile"
                placeholder="enter guest mobile"
              />
            </div>
            <div className="mb-3">
            {/* <Label required text="Address" />
            <Form.Item
              // label={"Address"}
              // initialValue={values.guest_add}
              name="guest_add"
              // onValuesChange={(value) => setFieldValue("guest_outstation", value)}
              // rules={[
              //   {
              //     required: true,
              //     message: "Address is required",
              //   },
              //   {
              //     validator: (_,value) => {
              //       if(value && value.length < 3) {
              //         return Promise.reject('Must be alteast 3 chars');
              //       } else {
              //         return Promise.resolve();
              //       }
              //     }
              //   }
              // ]}
            >
              <TextArea placeholder="enter address" />
              {errors.guest_add && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_add" />
                  </div>
                )}
            </Form.Item> */}
           
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Address" }}
                name="guest_add"
                placeholder="enter guest address"
              />
            </div>
            {/* <ShouldRender check={!guest}> */}
              <div className="mb-3">
                <Label required text="Locality" />
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  value={values.guest_outstation}
                  onChange={(value) => setFieldValue("guest_outstation", value)}
                  placeholder="Select Locality"
                >
                  <Select.Option value={GuestOutstation.Local}>
                    Local
                  </Select.Option>
                  <Select.Option value={GuestOutstation.Outstation}>
                    Out Station
                  </Select.Option>
                </Select>
                {errors.guest_outstation && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_outstation" />
                  </div>
                )}
              </div>
              <div className="mb-3">
                {/* <Label required text="Guest Invited" /> */}
              <Form.Item
              label={"Guest Invited"}
              name="guest_invited"
              rules={[
                {
                  required: true,
                  message: "Invite type is required",
                },
              ]}
            >
              <Select
                // value={}
                defaultValue={values?.guest_invited}
                placeholder="Select Invite Type"
                onChange={(e, values) => handleSetHideGuestInvited(e, values)}
                  // onChange={(value) => setFieldValue("guest_invited", value)}
              >
                <Select.Option value="">Select Option</Select.Option>
                <Select.Option value={GuestInvited.Family}>
                  Family
                </Select.Option>
                <Select.Option value={GuestInvited.Individual}>
                  Individual
                </Select.Option>
              </Select>
            </Form.Item>
            <div className="mb-3">
            <Form.Item
              label={"Number Of Guest Expected"}
              name="guest_expected_nos"
              rules={[
                {
                  // @ts-ignore: Object is possibly 'null'
                  required: hideGuestInvited === "Individual" ? false : true,
                  message: "Guest number is required",
                },
              ]}
            >
              <Input
                // @ts-ignore: Object is possibly 'null'
                // onChange={(value) => setFieldValue("guest_expected_nos", value.target.value)}
                onChange={(value)=>handleInput(value)}
                defaultValue={values?.guest_expected_nos}
                disabled={hideGuestInvited === "Individual" ? true : false}
                type="number"
                min={"1"}
                placeholder="enter  expected guest number"
              />
            </Form.Item>
              </div>
                {/* <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  value={values.guest_invited}
                  onChange={(value) => setFieldValue("guest_invited", value)}
                  placeholder="Select Guest Type"
                >
                  <Select.Option value={GuestInvited.Family}>
                    Family
                  </Select.Option>
                  <Select.Option value={GuestInvited.Individual}>
                    Individual
                  </Select.Option>
                </Select> */}
                {/* {errors.guest_invited && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_invited" />
                  </div>
                )} */}
              </div>
              {/* <div className="mb-3">
                <FormInput
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  label={{ required: true, text: "Number Of Guest Expected" }}
                  name="guest_expected_nos"
                  placeholder="enter expected number of guest"
                />
              </div> */}
              <div className="mb-3">
                <Label required text="Invitation Type" />
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  value={values.guest_invitation_type}
                  onChange={(value) =>
                    setFieldValue("guest_invitation_type", value)
                  }
                  placeholder="Select Invitation Type"
                >
                  <Select.Option value={InvitationType.Digitally}>
                    Digitally
                  </Select.Option>
                  <Select.Option value={InvitationType.Courier}>
                    Courier
                  </Select.Option>
                  <Select.Option value={InvitationType.Personally}>
                    Personally
                  </Select.Option>
                </Select>
                {errors.guest_invitation_type && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_invitation_type" />
                  </div>
                )}
              </div>
              <div className="mb-3">
                <Label required text="Date Of Arrival" />
                <DatePicker
                  disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  value={values.guest_date_of_arrival}
                  onChange={(value) =>
                    setFieldValue("guest_date_of_arrival", value)
                  }
                />
                {errors.guest_date_of_arrival && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_date_of_arrival" />
                  </div>
                )}
              </div>
            {/* </ShouldRender> */}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditGuest;
