import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import { IEvent, IVendor, IEventVendor } from "src/types";
import { clonseJSON } from "src/utils/json";
import { ShouldRender } from "src/components/ShouldRender";
import EditVendor from "./EditVendor";
import { updateSingleEvent } from "src/api-core/Events";
import { string } from "yup";

interface Props {
  event: IEvent | undefined;
  loading: boolean;
  update: (data: any) => Promise<void>;
  vendorOptions: IVendor[];
}

interface VENDORS {
  vendor_name: string;
  vendor_work: string;
  vendor_mobile: string;
  scope_of_work: string;
  due_amount: string;
  paid_amount: string;
  total_package: string;
  arriving_time: string;
}
interface TESTVENDOR {
  arriving_time: string;
  due_amount: string;
  paid_amount: string;
  scope_of_work: string;
  total_package: string;
  vendor_name: string;
  vendor_mobile: string;
  vendor_work: string;
}

interface DATA {
  event_vendors: {
    vendors: TESTVENDOR[];
  };
}

interface BaseColumn {
  title: string;
  dataIndex: string;
  key: string;
  render?: (
    _: any,
    record: TESTVENDOR
  ) => undefined | React.ReactNode | boolean;
  onFilter?: (_: any, record: TESTVENDOR) => boolean;
  filteredValue?: string[];
}

export const VendorTable = ({
  event,
  loading,
  update,
  vendorOptions,
}: Props) => {
  const { event_vendors = {} } = event || {};
  const { vendors = [] }: any = event_vendors;
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState<number>(-1);
  const [searchedText, setSearchedText] = React.useState<string>("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleUpdate = (data: Partial<IEvent>) => {
    if (event) {
      const { _id, client_id, event_type, event_title, event_start_date } =
        event;
      const payload = {
        client_id,
        event_type,
        event_title,
        event_start_date,
        ...data,
      };

      return updateSingleEvent(_id, payload).then(update).then(handleClose);
    }
  };

  const handleSave = async (values: TESTVENDOR) => {
    let updatedEventVendors: any = clonseJSON(event_vendors);

    if (recordIndex === -1) {
      const data: DATA = {
        event_vendors: updatedEventVendors,
      };
      if (vendors.length > 0) {
        updatedEventVendors?.vendors.push(values);
        handleUpdate(data)?.then(update).then(handleClose);
      } else {
        let updatedEventVendors: any = clonseJSON(values);
        const data = {
          event_vendors: {
            vendors: [updatedEventVendors],
          },
        };

        handleUpdate(data)?.then(update).then(handleClose);
      }
    } else {
      let updatedEventVendors: any = clonseJSON(event_vendors);

      updatedEventVendors.vendors[recordIndex] = values;
      const data = {
        event_vendors: updatedEventVendors,
      };

      handleUpdate(data)?.then(update).then(handleClose);
    }
  };

  const handleDelete = (index: number) => {
    //To Do
    // const updatedEventVendors = clonseJSON(event_vendors).filter(
    //   (_, i) => index !== i
    // );

    // const data: any = {
    //   event_vendors: updatedEventVendors,
    // };
    // handleUpdate(data)?.then(update);

    const updatedEventVendors: [] = vendors.filter(
      (items: TESTVENDOR, i: number) => index !== i
    );

    const data = {
      event_vendors: {
        vendors: updatedEventVendors,
      },
    };

    handleUpdate(data)?.then(update);
  };

  const getVendorAttribute = (id: string, key: keyof IVendor) => {
    const vendor: IVendor | any = vendors.find(
      (item: any) => item.vendor_name === id
    );

    return vendor?.[key] || "N/A";
  };

  const defaultColumns: BaseColumn[] = [
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (_: any, record: TESTVENDOR) =>
        getVendorAttribute(record.vendor_name, "vendor_name"),
    },
    {
      title: "Vendor Work",
      dataIndex: "vendor_work",
      key: "vendor_work",
      render: (_: any, record: TESTVENDOR) =>
        getVendorAttribute(record.vendor_name, "vendor_work"),
    },
    {
      title: "Vendor Mobile",
      dataIndex: "vendor_mobile",
      key: "vendor_mobile",
      render: (vendor_mobile: IEventVendor) => (
        <a href={`tel:${vendor_mobile}`}>{`${vendor_mobile}`}</a>
      )
    },
    {
      title: "Scope of work",
      dataIndex: "scope_of_work",
      key: "scope_of_work",
      render: (_: any, record: TESTVENDOR) =>
        getVendorAttribute(record.vendor_name, "scope_of_work"),
    },
    {
      title: "Due Amount",
      dataIndex: "due_amount",
      key: "due_amount",
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_amount",
      key: "paid_amount",
    },
    {
      title: "Total Package",
      dataIndex: "total_package",
      key: "total_package",
    },
    {
      title: "Arriving Time",
      dataIndex: "arriving_time",
      key: "arriving_time",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(record.vendor_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.vendor_work)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.vendor_mobile)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: TESTVENDOR) => {
        const index = vendors.findIndex(
          (vendor: any) => vendor.vendor_name === record.vendor_name
        );
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data </Button>
      </div>
      <Input.Search
        size="large"
        placeholder="Search"
        style={{ marginBottom: 8, width: 280 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value);
        }}
      />
      <Table
        columns={defaultColumns}
        dataSource={vendors}
        key="_id"
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditVendor
          open={open}
          handleClose={handleClose}
          handleSave={handleSave}
          title={recordIndex === -1 ? "Add Vendor" : "Edit Vendor"}
          vendorOptions={vendorOptions}
          vendor={recordIndex === -1 ? [] : vendors[recordIndex]}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
