import { Button, Input, Select, message, Form, Table } from "antd";
import React, { useState, useEffect } from "react";
import EventClientCreate from "../Users/EventClientCreate";

import { getAllRoles } from "src/api-core/RolesPermissions";
import { createUser, getAllUserPages } from "src/api-core/User";
import { EventType } from "src/types";
import TextArea from "antd/lib/input/TextArea";
import { formatDate, viewCurrentDates } from "src/utils/helper";
import { updateEvent, updateSingleEvent } from "src/api-core/Events";
import { useParams } from "react-router-dom";
import { Option } from "antd/lib/mentions";

const EditCurrentEvent = ({ event, loading, update }) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [coordinatorData, setCoordinatorData] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [open, setOpen] = useState(false);
  const [clientID, setSetClientID] = useState("");
  const [coordinatorNames, setCoordinatorNames] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [userTitle, setUserTitle] = useState("");
  const [form] = Form.useForm();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams();

  let client_role;
  let coordinator_role;

  const fetchAllRoles = async () => {
    const res = await getAllRoles();
    client_role = res.role.filter((role) => role.role_name === "Client");
    coordinator_role = res.role.filter(
      (role) => role.role_name === "Coordinator"
    );
    function roleSetter(arr, roleName) {
      const roles = arr.filter((items) => items.role_name === roleName)[0]?._id;
      return roles;
    }
    setSetClientID(roleSetter(res?.role, "Client"));

    if (userTitle === "Create Client") {
      setSetClientID(roleSetter(res?.role, "Client"));
    } else if (userTitle === "Create Coordinator") {
      setSetClientID(roleSetter(res?.role, "Coordinator"));
    }
  };

  const fetchAllUser = async () => {
    const resRoles = await getAllRoles();
    client_role = resRoles.role.filter((role) => role.role_name === "Client");
    coordinator_role = resRoles.role.filter(
      (role) => role.role_name === "Coordinator"
    );

    const res = await getAllUserPages(1000, 1);
    setClientData(
      res.user.filter((client) => client.role_id._id === client_role[0]._id)
    );
    setCoordinatorData(
      res.user.filter((coor) => coor.role_id._id === coordinator_role[0]._id)
    );
  };

  useEffect(() => {
    fetchAllUser();
  }, [isModalOpen, update]);

  useEffect(() => {
    fetchAllRoles();
  }, [userTitle]);

  const coordinatorOptions = coordinatorData.map((coor) => {
    return {
      label: coor.name,
      value: coor._id?.toString(),
    };
  });

  const handleSave = async () => {
    const res = await createUser();
    if (res.success) {
      if (userTitle === "Create Coordinator") {
        message.success("Coordinator created successfully");
      } else if (userTitle === "Create Client") {
        message.success("Client Created Successfully");
      }
      setOpen(false);
      await fetchAllUser(); // Fetch all users again to update the clientData dropdown
    }
    if (res.response && res.response.status === 400) {
      message.error(res.response.data.msgText);
    }
  };

  const validateCoordinatorSelection = () => {
    if (selectedCoordinators.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject("Coordinator Name is required");
  };

  const handleSelectChange = (value) => {
    // setSelectedCoordinators(value);

    // console.log(value, "val");

    // selectedCoordinator.push(...value);
    // console.log(selectedCoordinator, "coordinatorids");
    callBack(value);
  };
  const [selectedCoordinators, setSelectedCoordinators] = useState([]);
  const [eventDates, setEventDates] = useState({});

  const onFinishEventDetailForm = async (values) => {
    const payload = {
      client_id: values.client_id,
      coordinator_ids: selectedCoordinators,
      event_descp: values.event_descp,
      event_end_date: eventDates.end,
      event_remark: values.event_remark,
      event_start_date: eventDates.start,
      event_title: values.event_title,
      event_type: values.event_type,
      hotels: event?.hotels,
      event_vendors: event?.event_vendors,
      event_foodbev: event?.event_foodbev,
      event_proddecor: event?.event_proddecor,
    };

    const res = await updateSingleEvent(id, payload);

    if (res.success) {
      message.success("Event Updated Successfully");
      update();
    } else {
      if (res?.response?.data) {
        for (let key in res?.response?.data) {
          message.error(res?.response?.data[key]);
        }
      }
    }
    // setEventDetails(payload);
  };

  const callBack = (payload) => {
    setSelectedCoordinators(payload);
  };

  const dates = (payload) => {
    setEventDates({
      start: payload.start,
      end: payload.end,
    });
  };

  useEffect(() => {
    const res = coordinatorData
      ?.filter((obj1) =>
        event?.coordinator_ids?.some((obj2) => obj1._id == obj2)
      )
      ?.map((items) => items);

    res && setCoordinatorNames(res);
  }, [coordinatorData, event]);

  useEffect(() => {
    const updatedDataSource = coordinatorNames?.map((coordinators) => ({
      name: coordinators.name,
      mobile: coordinators.mobile,
      email: coordinators.email,
    }));
    setDataSource(updatedDataSource);
  }, [coordinatorNames]);

  

  const columns = [
    {
      id: 1,
      title: "Coordinator Name",
      dataIndex: "name",
      key: "name",
    },
    {
      id: 1,
      title: "Mobile Num.",
      dataIndex: "mobile",
      key: "mobile",
      render: (mobile) => (
        <a href={`tel: ${mobile}`}>{mobile}</a>
      )
    },
    {
      id: 1,
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  return (
    <>
      <EventDetail
        onFinish={onFinishEventDetailForm}
        initialValues={event}
        selectedCoordinator={selectedCoordinators}
        callBack={callBack}
        event={event}
        dates={dates}
      />

      <Table columns={columns} dataSource={dataSource} />
    </>
  );
};

export default EditCurrentEvent;
const EventDetail = ({
  onFinish,
  initialValues,
  selectedCoordinator,
  callBack,
  event,
  dates,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [coordinatorData, setCoordinatorData] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [open, setOpen] = useState(false);
  const [clientID, setSetClientID] = useState("");
  const [form] = Form.useForm();
  const [userTitle, setUserTitle] = useState("");
  const [preSelectedCoordinators, setPreSelectedCoordinators] = useState(
    initialValues?.coordinator_ids
      ? initialValues?.coordinator_ids.map((coordinator) =>
          coordinator?.toString()
        )
      : selectedCoordinator
  );

  const [eventStartDate, setEventStartDate] = useState(
    initialValues?.event_start_date
  );
  const [eventEndDate, setEventEndDate] = useState(
    initialValues?.event_end_date
  );

  //   const [dates, setDates] = useState({
  //     start: "",
  //     end: "",
  //   });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let client_role;
  let coordinator_role;

  useEffect(() => {
    dates({
      start: eventStartDate,
      end: eventEndDate,
    });
  }, [eventStartDate, eventEndDate]);

  const fetchAllRoles = async () => {
    const res = await getAllRoles();
    client_role = res.role.filter((role) => role.role_name === "Client");
    coordinator_role = res.role.filter(
      (role) => role.role_name === "Coordinator"
    );
    function roleSetter(arr, roleName) {
      const roles = arr.filter((items) => items.role_name === roleName)[0]?._id;
      return roles;
    }
    setSetClientID(roleSetter(res?.role, "Client"));

    if (userTitle === "Create Client") {
      setSetClientID(roleSetter(res?.role, "Client"));
    } else if (userTitle === "Create Coordinator") {
      setSetClientID(roleSetter(res?.role, "Coordinator"));
    }
  };

  const fetchAllUser = async () => {
    const resRoles = await getAllRoles();
    client_role = resRoles.role.filter((role) => role.role_name === "Client");
    coordinator_role = resRoles.role.filter(
      (role) => role.role_name === "Coordinator"
    );

    const res = await getAllUserPages(1000, 1);
    setClientData(
      res.user.filter((client) => client.role_id._id === client_role[0]._id)
    );
    setCoordinatorData(
      res.user.filter((coor) => coor.role_id._id === coordinator_role[0]._id)
    );
  };

  useEffect(() => {
    fetchAllUser();
  }, [isModalOpen]);

  useEffect(() => {
    fetchAllRoles();
  }, [userTitle]);

  const coordinatorOptions = coordinatorData.map((coor) => {
    return {
      label: coor.name,
      value: coor._id?.toString(),
    };
  });

  const handleSave = async (values) => {
    const res = await createUser(values);
    if (res.success) {
      if (userTitle === "Create Coordinator") {
        message.success("Coordinator created successfully");
      } else if (userTitle === "Create Client") {
        message.success("Client Created Successfully");
      }
      setOpen(false);
      await fetchAllUser(); // Fetch all users again to update the clientData dropdown
    }
    if (res.response && res.response.status === 400) {
      message.error(res.response.data.msgText);
    }
  };

  const validateCoordinatorSelection = () => {
    if (preSelectedCoordinators.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject("Coordinator Name is required");
  };

  const handleSelectChange = (value) => {
    // setSelectedCoordinators(value);

    setPreSelectedCoordinators(value);

    callBack(preSelectedCoordinators);
  };
  const eventType = [
    { id: 1, value: "Wedding" },
    { id: 2, value: "Anniversary" },
    { id: 3, value: "Birthday" },
    { id: 4, value: "Weekend Party" },
    { id: 5, value: "Reception Party" },
  ];

  useEffect(() => {
    callBack(preSelectedCoordinators);
  }, [preSelectedCoordinators]);

  return (
    <div className="relative">
      <div className="">
        <button
          onClick={() => {
            setOpen(true);
            setUserTitle("Create Client");
          }}
          className="absolute right-0 z-10 p-2 text-white bg-blue-500 cursor-pointer top-6 whitespace-nowrap max-w-fit"
        >
          Add Client
        </button>
      </div>
      <Form form={form} onFinish={() => handleSave()}>
        <EventClientCreate
          open={open}
          handleClose={handleClose}
          clientID={clientID}
          handleSave={handleSave}
          title={userTitle}
        />
      </Form>

      <Form layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            label={"Event Type"}
            name="event_type"
            rules={[
              {
                required: true,
                message: "Event type is required",
              },
            ]}
          >
            <select className="w-full h-full p-[0.5rem]">
              <option value={""}>Select Event Type</option>
              {eventType.map((items) => (
                <option
                  value={items.value}
                  selected={items.value === event.event_type}
                  key={items.id}
                >
                  {items.value}
                </option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            label={"Client Name"}
            name="client_id"
            rules={[
              {
                required: true,
                message: "Client Name is required",
              },
            ]}
          >
            {/* <Select style={{ width: "65%" }} placeholder="Select Client Name">
              <Select.Option value="">Select Option</Select.Option>
              {clientData.map((client) => (
                <Select.Option key={client._id} value={client._id}>
                  {client.name}
                </Select.Option>
              ))}
              {event?.client_id}
            </Select> */}
            <select className="w-full h-full p-[0.5rem]">
              <option>Select Client</option>
              {clientData.map((client) => (
                <option
                  value={client._id}
                  key={client._id}
                  selected={client._id === event.client_id}
                >
                  {client.name}
                </option>
              ))}
            </select>
          </Form.Item>

          <Form.Item
            label={"Coordinator Name"}
            name="coordinator_ids"
            rules={[
              {
                validator: validateCoordinatorSelection,
                required: true,
              },
            ]}
          >
            {/* <Select
              mode="multiple"
              allowClear
              options={coordinatorOptions}
              onChange={(values) => handleSelectChange(values)}
              defaultValue={preSelectedCoordinators}
              showSearch
            /> */}

            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(values) => handleSelectChange(values)}
              value={preSelectedCoordinators}
            >
              {coordinatorOptions.map((coor) => (
                <Option key={coor.value} value={coor.value} label={coor.label}>
                  {coor.label}
                </Option>
              ))}
            </Select>

            <button
              onClick={() => {
                setOpen(true);
                setUserTitle("Create Coordinator");
              }}
              className=" ml-4 gap-1 right-0 z-10 p-2 text-white bg-blue-500 cursor-pointer top-6 whitespace-nowrap max-w-fit"
            >
              Add Coordinator
            </button>
            <Form form={form} onFinish={() => handleSave()}>
              <EventClientCreate
                open={open}
                handleClose={handleClose}
                clientID={clientID}
                handleSave={handleSave}
                title={userTitle}
              />
            </Form>
          </Form.Item>

          <Form.Item
            label={"Event Title"}
            name="event_title"
            rules={[
              {
                required: true,
                message: "Event title is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input placeholder="enter event title" />
          </Form.Item>
          <Form.Item
            label={"Event Description"}
            name="event_descp"
            rules={[
              {
                required: true,
                message: "Event description is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be alteast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <TextArea placeholder="enter event description" />
          </Form.Item>
          <div className="flex">
            {/* <Form.Item
              label={"Event Start Date"}
              name="event_start_date"
              rules={[
                {
                  required: true,
                  message: "Event Start date is required",
                },
              ]}
            >
              <Input
                type="date"
                // onChange={(e) => setEndDate(e.target.value)}
                // required
                min={viewCurrentDates}
              />
              {formatDate(initialValues.event_start_date, "DD-MM-YYYY")}
            </Form.Item> */}

            <Form.Item label="Event Start Date" name="event_start_date">
              <Input
                type="date"
                value={formatDate(eventStartDate, "YYYY-MM-DD")}
                onChange={(e) => setEventStartDate(e.target.value)}
              />
              <span className="invisible">
                {" "}
                {formatDate(eventStartDate, "YYYY-MM-DD")}{" "}
              </span>
            </Form.Item>
          </div>
          <Form.Item label={"Event End Date"} name="event_end_date">
            <Input
              type="date"
              value={formatDate(eventEndDate, "YYYY-MM-DD")}
              onChange={(e) => setEventEndDate(e.target.value)}
            />
            <span className="invisible">
              {" "}
              {formatDate(eventEndDate, "YYYY-MM-DD")}{" "}
            </span>
          </Form.Item>
          <Form.Item
            label={"Event Remarks"}
            name="event_remark"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <TextArea placeholder="Enter remarks" />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};
