import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userLogin } from "../../api-core/User";
import { message } from "antd";
import LoadingSpinner from "../Loading";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandle = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // console.log("inputs", inputs);
    if (inputs) {
      setLoading(true);
      const res = await userLogin(inputs);

      if (res.success && res?.user.role_id.role_name !== "Admin") {
        message.error("Login Failed");
        return setLoading(false);
      }
      if (!res.success) {
        message.error("Wrong Email/Password");
      }

      setLoading(false);

      res?.success && message.success("Login Success");
      const localStorageData = {
        token: res?.token,
        userName: res?.user.name,
      };

      localStorage.setItem("token", JSON.stringify(localStorageData));

      navigate("admin/dashboard");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);
  return (
    <section className="h-screen">
      <div className="px-6 h-full text-gray-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
          <div className="grow-0 shrink-1 md:shrink-0 basis-auto flex justify-center xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
            <img src="/Assest/bbe.png" className="w-[70%]" alt="Sample image" />
          </div>
          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <form onSubmit={submitHandle}>
              <div className="flex flex-row items-center justify-center ">
                <p className="text-lg mb-4 mr-4">Admin Sign In</p>
              </div>

              {/* <!-- Email input --> */}
              <div className="mb-6">
                <input
                  type="text"
                  name="email"
                  value={inputs.email}
                  onChange={inputChange}
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Email address"
                  required
                />
              </div>

              {/* <!-- Password input --> */}
              <div className="mb-6">
                <input
                  type="password"
                  name="password"
                  value={inputs.password}
                  onChange={inputChange}
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Password"
                  required
                />
              </div>

              {/* <div className="flex justify-end items-end mb-6">
                <Link to="/forgotpassword">
                  <span className="text-gray-800">Forgot password?</span>
                </Link>
              </div> */}

              <div className="text-center lg:text-left">
                <button
                  type="submit"
                  className={`inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ${loading} ? cursor-not-allowed: cursor-pointer`}
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : "Login"}
                </button>
                {/* <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                  Don't have an account?
                  <Link to="/register">
                    <span className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out">
                      Register
                    </span>
                  </Link>
                </p> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
