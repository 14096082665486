import Axios, { overrideHeader } from "../Axios";

export const getAllFreelancer = async () => {
  try {
    const response = await Axios.get(`/admin/freelancer`);
    return response.data;
  } catch (error) {
    return error;
  }
};

/*Get free lancer according to pages */
export const getAllFreelancerPages = async (row, pages) => {
  try {
    const response = await Axios.get(`/admin/freelancer?r=${row}&p=${pages}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllFreelancerAssignee = async () => {
  try {
    const response = await Axios.get(`/admin/freelancerassignedevent`);
    return response.data;
  } catch (error) {
    return error;
  }
};
/* get All free lancer assignee according to pages*/

export const getAllFreelancerAssigneePages = async (row, pages) => {
  try {
    const response = await Axios.get(
      `/admin/freelancerassignedevent?r=${row}&p=${pages}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSingleFreelancerAssignee = async (id) => {
  try {
    const response = await Axios.get(
      `/admin/freelancerassignedevent/read/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getsingleFreelancer = async (id) => {
  try {
    const response = await Axios.get(`/admin/freelancer/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createFreelancer = async (data) => {
  overrideHeader();
  try {
    const response = await Axios.post(`/admin/freelancer/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateFreelancer = async (data, id) => {
  overrideHeader();
  try {
    const response = await Axios.post(`/admin/freelancer/update/${id}`, data);
    return response.data;
  } catch (error) {}
};

export const createFreelancerAssignee = async (data) => {
  try {
    const response = await Axios.post(
      `/admin/freelancerassignedevent/create`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteFreelancerAssignee = async (ids) => {
  const data = {
    ids,
  };
  try {
    const response = await Axios.post(
      `/admin/freelancerassignedevent/delete`,data);
    return response.data;
  } catch (error) {
    return error;
  }
};

/*Update Freelancer Assignee */

export const updateFreelancerAssignee = async (data, id) => {
  try {
    const response = await Axios.post(
      `/admin/freelancerassignedevent/update/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteFreelancer = async (id) => {
  try {
    const response = await Axios.post(`/admin/freelancer/delete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};