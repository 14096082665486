import moment from "moment";
import * as Yup from "yup";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import { Label } from "src/components/Label";

const AddCars = ({ handleClose, handleSave, data, open, title, event }) => {
  const initialValues = {
    vendor_id: data?.vendor_id,
    owner_name: data?.owner_name,
    car_reg: data?.car_reg,
    car_model: data?.car_model,
    car_number: data?.car_number,
    car_type: data?.car_type,
    driver_name: data?.driver_name,
    driver_mobile: data?.driver_mobile,
  };
  
  const validationSchema = Yup.object({
    vendor_id: Yup.string().required("Vendor name is required"),
    owner_name: Yup.string().min(3).required("Owner name is required"),
    car_reg: Yup.string().required("Car registration is required").min(3),
    car_model: Yup.string().required("Car Model is required").min(3),
    car_number: Yup.string().required("Car Number is required").min(3),
    car_type: Yup.string().required("Car Type is required"),
    driver_name: Yup.string().required("Driver Name is required").min(3),
    driver_mobile: Yup.number()
      .test(
        "len",
        "Must be exactly 10 digits",
        (val) => val?.toString().length === 10
      )
      .required("Guest Mobile is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        handleSave({
          vendor_id: values?.vendor_id,
          owner_name: values?.owner_name,
          car_reg: values?.car_reg,
          car_model: values?.car_model,
          car_number: values?.car_number,
          car_type: values?.car_type,
          driver_name: values?.driver_name,
          driver_mobile: values?.driver_mobile,
        })
      }
    >
      {({ setFieldValue, handleSubmit, values, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            <div className="mb-3">
              <Label required text="Vendor Name" />
              <Select
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                onChange={(value) => setFieldValue("vendor_id", value)}
                value={values.vendor_id}
              >
                <Select.Option value="">Select Vendor Name</Select.Option>
                {event?.vendors.map((items) => (
                  <Select.Option value={items._id}>
                    {items.vendor_name}
                  </Select.Option>
                ))}
              </Select>

              {errors.vendor_id && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="vendor_id" />
                </div>
              )}
            </div>

            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Owner Name", required: true }}
                name="owner_name"
                placeholder="enter owner name"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Car Registration", required: true }}
                name="car_reg"
                placeholder="Pune"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Car Model", required: true }}
                name="car_model"
                placeholder="Ford Figo"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Car Number", required: true }}
                name="car_number"
                placeholder="MH 08 PU 9874"
              />
            </div>

            <div className="mb-3">
              <Label required text="Car Type" />
              <Select
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                onChange={(value) => setFieldValue("car_type", value)}
                value={values.car_type}
              >
                {["Rental", "Private"].map((items) => (
                  <Select.Option value={items}>{items}</Select.Option>
                ))}
              </Select>
              {errors.car_type && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="car_type" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Driver Name", required: true }}
                name="driver_name"
                placeholder="enter driver name"
              />
            </div>

            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Driver Mobile Number", required: true }}
                name="driver_mobile"
                placeholder="contact number"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddCars;
