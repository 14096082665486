import { DatePicker, Modal, Select, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import * as Yup from "yup";
import { IArrival, IGuestList, IVendorCar } from "src/types";
import { Label } from "src/components/Label";
import moment from "moment";
import { TIME_FORMATS, TIME_PICKER_FORMAT } from "src/constants";
import { ShouldRender } from "src/components/ShouldRender";

export type Arrival = Omit<IArrival, "_id" | "client_id" | "event_id">;

interface Props {
  handleClose: () => void;
  handleSave: (values: Arrival) => void;
  arrival?: Arrival;
  guests: IGuestList[];
  open: boolean;
  title: string;
  vendorCars: IVendorCar[];
}

const validationSchema = Yup.object({
  date_of_arrival: Yup.string().required("Date of arrival is required"),
  no_of_guest_arrived: Yup.number().required(
    "Arrived guest number is required"
  ),
  mode_of_arrival: Yup.string().required("Mode of arrival is required"),
  expected_arrival_time: Yup.string().required(
    "Expected arrival time is required"
  ),
  arrived_at: Yup.string().required("Arrived at is required"),
  welcome_checklist: Yup.string().min(3,"Welcome must be at least 3 characters"),
  guest_id: Yup.string().required("Please select Guest"),
  car_id: Yup.string().required("Please select Car"),
  special_note : Yup.string().min(3,"Special note must be at least 3 characters"),
});

const EditArrival = ({
  handleClose,
  handleSave,
  arrival,
  guests,
  open,
  title,
  vendorCars,
}: Props) => {
  const initalValues = {
    date_of_arrival: arrival?.date_of_arrival
      ? moment(arrival?.date_of_arrival)
      : undefined,
    no_of_guest_arrived: arrival?.no_of_guest_arrived,
    mode_of_arrival: arrival?.mode_of_arrival,
    expected_arrival_time: arrival?.expected_arrival_time
      ? moment(arrival?.expected_arrival_time, TIME_PICKER_FORMAT)
      : undefined,
    arrived_at: arrival?.arrived_at
      ? moment(arrival?.arrived_at, TIME_PICKER_FORMAT)
      : undefined,
    welcome_checklist: arrival?.welcome_checklist,
    special_note: arrival?.special_note,
    guest_id: arrival?.guest_id ? arrival.guest_id : undefined,
    car_id: arrival?.car_id,
  };
  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSave({
          date_of_arrival: values.date_of_arrival!.format(
            TIME_FORMATS.API_TIME
          ),
          no_of_guest_arrived: values.no_of_guest_arrived!,
          mode_of_arrival: values.mode_of_arrival!,
          expected_arrival_time: values.expected_arrival_time!.format(
            TIME_FORMATS.TIME
          ),
          arrived_at: values.arrived_at!.format(TIME_FORMATS.TIME),
          welcome_checklist: values.welcome_checklist!,
          special_note: values.special_note,
          guest_id: values.guest_id!,
          car_id: values.car_id!,
        });
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            {/* <ShouldRender check={!arrival}> */}
              <div className="mb-3">
                <Label required text="Guest" />
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  placeholder="Select Guest"
                  onChange={(id) => setFieldValue("guest_id", id)}
                  value={values.guest_id}
                >
                  {guests?.map((guest) => (
                    <Select.Option key={guest._id} value={guest._id}>
                      {guest.guest_name}
                    </Select.Option>
                  ))}
                </Select>
                {errors.guest_id && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_id" />
                  </div>
                )}
              </div>
            {/* </ShouldRender> */}
            {/* <ShouldRender check={!arrival}> */}
              <div className="mb-3">
                <Label required text="Car" />
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  onChange={(id) => setFieldValue("car_id", id)}
                  placeholder="Select Car"
                  value={values.car_id}
                >
                  {vendorCars?.map((car) => (
                    <Select.Option key={car._id} value={car._id}>
                      {car.car_model}
                    </Select.Option>
                  ))}
                </Select>
                {errors.car_id && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="car_id" />
                  </div>
                )}
              </div>
            {/* </ShouldRender> */}
            <div className="mb-3">
              <Label required text="Date of Arrival" />
              <DatePicker
                disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                value={values.date_of_arrival}
                onChange={(value) => setFieldValue("date_of_arrival", value)}
              />
              {errors.date_of_arrival && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="date_of_arrival" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "No of guest" }}
                type="number"
                name="no_of_guest_arrived"
                placeholder="enter no of guest arrived"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Mode of Arrival" }}
                name="mode_of_arrival"
                placeholder="enter mode_of_arrival"
              />
            </div>
            <div className="grid grid-cols-2 gap-3 mb-3">
              <div className="m-auto">
                <Label required text="Expected Arrival Time" />
                <TimePicker
                  format={TIME_PICKER_FORMAT}
                  value={values.expected_arrival_time}
                  onChange={(value) =>
                    setFieldValue("expected_arrival_time", value)
                  }
                />
                {errors.expected_arrival_time && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="expected_arrival_time" />
                  </div>
                )}
              </div>
              <div className="m-auto">
                <Label required text="Arrived At" />
                <TimePicker
                  format={TIME_PICKER_FORMAT}
                  value={values.arrived_at}
                  onChange={(value) => setFieldValue("arrived_at", value)}
                />
                {errors.arrived_at && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="arrived_at" />
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Welcome Text" }}
                name="welcome_checklist"
                placeholder="enter welcome checklist"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Special Note" }}
                name="special_note"
                placeholder="enter special note"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditArrival;
