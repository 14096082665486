import Axios, { overrideHeader } from "../Axios";

// Events //

export const getAllEvents = async (page = 1, row = 10) => {
  try {
    const response = await Axios.post(`/admin/event?r=${row}&p=${page}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSingleEvent = async (id) => {
  try {
    const response = await Axios.get(`/admin/event/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createEvent = async (data) => {
  try {
    const response = await Axios.post(`/admin/event/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateEvent = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/event/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteEvent = async (id) => {
  try {
    const response = await Axios.post(`/admin/event/delete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Sub Event //
export const getAllSubEvents = async () => {
  try {
    const response = await Axios.post(`/admin/event`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleSubEvent = async (id) => {
  try {
    const response = await Axios.get(`/admin/subEvent/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createSubEvent = async (data) => {
  try {
    const response = await Axios.post(`/admin/subEvent/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateSubEvent = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/subEvent/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteSubEvent = async (id) => {
  try {
    const response = await Axios.post(`/admin/subEvent/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Event Photo //

export const getAllEventPhoto = async () => {
  try {
    const response = await Axios.get(`/admin/eventPhoto`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createEventPhoto = async (data) => {
  overrideHeader();
  try {
    const response = await Axios.post(`/admin/eventPhoto/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleSubEventPhoto = async (id) => {
  try {
    const response = await Axios.get(`/admin/eventPhoto/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateEventPhoto = async (data, id) => {
  overrideHeader();
  try {
    const response = await Axios.post(`/admin/eventPhoto/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteEventPhoto = async (id) => {
  try {
    const response = await Axios.post(`/admin/eventPhoto/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

//  Create Event //

export const createCurrentEvent = async (data) => {
  try {
    const response = await Axios.post(`/admin/event/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Upload Event Images //

export const eventUploadImageFile = async (data) => {
  overrideHeader();
  try {
    const response = await Axios.post(`/admin/event/uploadfiles`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Update Single Event //
export const updateSingleEvent = async (id, data) => {
  const reconstructedData = data?.hotels
    ?.map((hotels) => hotels)
    ?.map((items) => items.hotel_rooms_required)
    ?.flat()
    ?.map((items) => items.room_nos)
    ?.flat()
    ?.filter((items) => {
      if (items.isBooked) {
        delete items.guestDetails;
        delete items.note;
      }
    });

  data.event_foodbev?.forEach((foodBev) => {
    if (foodBev.menu[0].hasOwnProperty("filename")) {
      foodBev.menu[0].file = foodBev.menu[0].filename;
    }
    delete foodBev.menu[0].filename;
  });

  data.event_proddecor?.forEach((decor) => {
    if (decor.decor_img[0].hasOwnProperty("filename")) {
      decor.decor_img[0].file = decor.decor_img[0].filename;
    }
    delete decor.decor_img[0].filename;
  });

  try {
    const response = await Axios.post(
      "/admin/event/update_single_event/" + id,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
