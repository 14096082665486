export enum RoomType {
  Deluxe = "Deluxe",
  Standard = "Standard",
}

export const hospitality_checklist = [
  {
    check_id: 1,
    check_name: "Clean Bedsheet",
  },
  {
    check_id: 2,
    check_name: "Clean Pillow",
  },
  {
    check_id: 3,
    check_name: "Clean Toilet",
  },
];

export interface IHotelRoom {
  _id: string;
  hotel_id: string;
  // hotel_id: {
  //   _id: string;
  //   hotel_name: string;
  // };
  room_no: number;
  floor_no: number;
  room_type: RoomType;
  hospitality_checklist: typeof hospitality_checklist;
  room_type_id:string
}
