import { DatePicker, Modal, Select, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import * as Yup from "yup";
import { IDeparture, IGuestList, IVendorCar } from "src/types";
import { Label } from "src/components/Label";
import moment from "moment";
import { TIME_FORMATS, TIME_PICKER_FORMAT } from "src/constants";
import { ShouldRender } from "src/components/ShouldRender";

export type Departure = Omit<IDeparture, "_id" | "client_id" | "event_id">;

interface Props {
  handleClose: () => void;
  handleSave: (values: Departure) => void;
  departure?: Departure;
  guests: IGuestList[];
  open: boolean;
  title: string;
  vendorCars: IVendorCar[];
}

const validationSchema = Yup.object({
  date_of_departure: Yup.string().required("Date of departure is required"),
  no_of_guest_arrived: Yup.number().required(
    "Departed guest number is required"
  ),
  mode_of_departure: Yup.string().required("Mode of departure is required"),
  departure_time: Yup.string().required("Departure time is required"),
  return_checklist: Yup.string().min(3,"Return text must be at least 3 characters"),
  guest_id: Yup.string().required("Please select Guest"),
  car_id: Yup.string().required("Please select Car"),
  special_note: Yup.string().min(3, "Special note must be at least 3 characters"),
});

const EditDeparture = ({
  handleClose,
  handleSave,
  departure,
  guests,
  open,
  title,
  vendorCars,
}: Props) => {
  const initalValues = {
    date_of_departure: departure?.date_of_departure
      ? moment(departure?.date_of_departure)
      : undefined,
    no_of_guest_arrived: departure?.no_of_guest_arrived,
    mode_of_departure: departure?.mode_of_departure,
    departure_time: departure?.departure_time
      ? moment(departure?.departure_time, TIME_PICKER_FORMAT)
      : undefined,
    return_checklist: departure?.return_checklist,
    special_note: departure?.special_note,
    guest_id: departure?.guest_id,
    car_id: departure?.car_id,
  };
  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSave({
          date_of_departure: values.date_of_departure!.format(
            TIME_FORMATS.API_TIME
          ),
          no_of_guest_arrived: values.no_of_guest_arrived!,
          mode_of_departure: values.mode_of_departure!,
          departure_time: values.departure_time!.format(TIME_FORMATS.TIME),
          return_checklist: values.return_checklist!,
          special_note: values.special_note,
          guest_id: values.guest_id!,
          car_id: values.car_id!,
        });
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            {/* <ShouldRender check={!departure}> */}
              <div className="mb-3">
                <Label required text="Guest" />
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  onChange={(id) => setFieldValue("guest_id", id)}
                  placeholder="Select Guest"
                  value={values.guest_id}
                >
                  {guests?.map((guest) => (
                    <Select.Option key={guest._id} value={guest._id}>
                      {guest.guest_name}
                    </Select.Option>
                  ))}
                </Select>
                {errors.guest_id && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_id" />
                  </div>
                )}
              </div>
            {/* </ShouldRender> */}
            {/* <ShouldRender check={!departure}> */}
              <div className="mb-3">
                <Label required text="Car" />
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  onChange={(id) => setFieldValue("car_id", id)}
                  placeholder="Select Car"
                  value={values.car_id}
                >
                  {vendorCars?.map((car) => (
                    <Select.Option key={car._id} value={car._id}>
                      {car.car_model}
                    </Select.Option>
                  ))}
                </Select>
                {errors.car_id && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="car_id" />
                  </div>
                )}
              </div>
            {/* </ShouldRender> */}
            <div className="mb-3">
              <Label required text="Date of Departure" />
              <DatePicker
                disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                value={values.date_of_departure}
                onChange={(value) => setFieldValue("date_of_departure", value)}
              />
              {errors.date_of_departure && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="date_of_departure" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "No of guest" }}
                type="number"
                name="no_of_guest_arrived"
                placeholder="enter no of guest departed"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Mode of Departure" }}
                name="mode_of_departure"
                placeholder="enter mode_of_departure"
              />
            </div>
            <div className="mb-3">
              <Label required text="Departure Time" />
              <TimePicker
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                format={TIME_PICKER_FORMAT}
                value={values.departure_time}
                onChange={(value) => setFieldValue("departure_time", value)}
              />
              {errors.departure_time && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="departure_time" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Return Text" }}
                name="return_checklist"
                placeholder="enter welcome checklist"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Special Note" }}
                name="special_note"
                placeholder="enter special note"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditDeparture;
