import React, { useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createEvent } from "../../../api-core/Events";
const CreateEvent = () => {
  const [eventImg, setEventImg] = useState("");
  const initialValues = {
    event_title: "",
    event_descp: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      event_title: Yup.string().required("event_title is required"),
      event_descp: Yup.string().required(" Event Description is required"),
    }),
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("event_img", eventImg);
      formData.append("event_title", values.event_title);
      formData.append("event_descp", values.event_descp);

      if (formData) {
        const res = await createEvent(formData);
        console.log("resFormData", res);
        action.resetForm();
      } else {
        console.log("error");
      }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Event</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Title
            </label>
            <input
              type="text"
              name="event_title"
              value={formik.values.event_title}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter event title"
            />
            {formik.errors.event_title && (
              <span className="text-red-500">{formik.errors.event_title}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Desciption
            </label>
            <textarea
              type="text"
              name="event_descp"
              value={formik.values.event_descp}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter event desc"
            />
            {formik.errors.event_descp && (
              <span className="text-red-500">{formik.errors.event_descp}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Image
            </label>
            <input
              type="file"
              name="event_img"
              onChange={(e) => setEventImg(e.target.files[0])}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              placeholder="Event Image"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateEvent);
