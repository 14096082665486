import { IHotel } from "./hotel";
import { IEventVendor } from "./vendor";

export enum EventType {
  Wedding = "Wedding",
  Anniversary = "Anniversary",
  Birthday = "Birthday",
  WeekendParty = "Weekend Party",
  ReceptionParty = "Reception Party"
}

export enum FoodType {
  Dinner = "Dinner",
  Breakfast = "Breakfast",
  Lunch = "Lunch",
  HiTea = "Hi Tea",
}

export interface IVendor {
  id: string;
  vendor_name: string;
  arrival_time:string; 
  
  vendor_work: string;
  vendor_mobile: number;
  blacklisted: boolean;
  scope_of_work:string;
}

export interface IEventCar {
  _id: string;
  car_model: string;
  car_number: string;
  driver_name: string;
  driver_mobile: number;
}

interface Menu {
  file: string;
  filename: string;
}

export interface IEventFoodNBeverages {
  food_type: FoodType;
  menu: Menu[];
  plates_guaranteed?: string;
  plates_used?: string;
  serve_date: string;
  serve_end_time?: string | undefined;
  serve_start_time?: string | undefined;
  plates_added?: string;
  plates_remaining?: string;
}

/*Type for Gallery Tab */
export interface IEventGallery {
  event_name:string;
  event_date:string;
  event_desc:string;
  event_photos:{
    file:string;
    filename:string;
  }

}

export interface IDeployedFreelancer{
  // deployedfreelancers: string | undefined;

  freelancer_name?: string;
  department_type: string | undefined;
  hours_worked?: number;
  expected_working_hours?: number;
  freelancer_id:string | undefined
  _id?: string;
}

export interface IEventProdDecor {
  decor_title: string;
  decor_img: {
    file: string;
    filename: string;
  }[];
  decor_date: string;
  expected_decor_time: string;
  // decor_remark?: string;
}

export interface IEvent {
  _id: string;
  client_id: string;
  event_descp: string;
  event_end_date: string;
  event_foodbev: IEventFoodNBeverages[];
  event_remark: string;
  event_start_date: string;
  event_title: string;
  event_type: EventType;
  hotels: IHotel[];
  event_vendors: {
vendors:IEventVendor[]
  } ;
  // event_car: IEventCar[];
  event_proddecor: IEventProdDecor[];
  deployedfreelancers: IDeployedFreelancer[];
}
