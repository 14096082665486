import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getSingleTrivia, updateTrivia } from "../../../api-core/Trivia";
import { message } from "antd";
const UpdateTrivia = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    title: "",
    descp: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchSingleTrivia = async () => {
      setloading(true);
      const res = await getSingleTrivia(id);
      setInputs({
        title: res.trivia[0]?.title,
        descp: res.trivia[0]?.descp,
      });
      setloading(false);
    };
    fetchSingleTrivia();
  }, [id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs) {
      const res = await updateTrivia(inputs, id);
      if (res.success == true) {
        setInputs({
          title: "",
          descp: "",
        });
        message.success("Updated Successfully");
        navigate("/admin/listoftrivia");
      }
      if (res.response.data) {
        for (let key in res.response.data) {
          message.error(res.response.data[key]);
        }
      }
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Edit Trivia</h1>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Trivia Title
            </label>
            <input
              type="text"
              name="title"
              value={inputs.title}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter trivia title"
            />
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Trivia Desciption
            </label>
            <textarea
              type="text"
              name="descp"
              value={inputs.descp}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter trivia desc"
            />
          </div>
        </div>
        <Link to={"/admin/listoftrivia"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >Cancel</button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update Trivia
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateTrivia);
