import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, Select } from "antd";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { MinusCircleOutlined } from "@ant-design/icons";

import { Label } from "src/components/Label";
import { ShouldRender } from "src/components/ShouldRender";

import { IHotelRoomResponse } from "./CreateHotel";
import { IHotelOption, hospitality_checklist, IHotelRoom } from "src/types";
import { createHotelRoom, getAllHotelRoomType } from "src/api-core/Hotel";
import { clonseJSON } from "src/utils/json";
import { useFetchHotelDetail } from "src/hooks/useFetchHotelDetail";

export interface HotelRoomsRequired {
  floor_no: number | undefined;
  room_nos: {
    standard: string | undefined;
    deluxe: string | undefined;
    suite: string | undefined;
  };
}

interface HotelRoom {
  hotel_id: string;
  hotel_rooms_required: HotelRoomsRequired[];
}

type FormErrors = {
  hotel_id: string;
  hotel_rooms_required: {
    floor_no: string;
    room_nos: {
      standard: string | undefined;
      deluxe: string | undefined;
      suite: string | undefined;
    };
  }[];
};

const roomRegex = /^(?!.*(\b\d+\b).*\b\1\b)\d+(,\d+)*$/;

const validationSchema = Yup.object({
  hotel_id: Yup.string().required("Please select Hotel"),
  hotel_rooms_required: Yup.array(
    Yup.object({
      floor_no: Yup.number().required("Floor Number is required"),
      // room_nos: Yup.string().required("Please enter rooms"),
      room_nos: Yup.object({
        standard: Yup.string().matches(roomRegex, "Repeated rooms not allowed"),

        deluxe: Yup.string().matches(roomRegex, "Repeated rooms not allowed"),

        suite: Yup.string().matches(roomRegex, "Repeated rooms not allowed"),
      }),
    })
  ).min(1),
});

interface Props {
  open: boolean;
  options: IHotelOption[];
  hotel_id?: string;
  handleClose: () => void;
  handleRoomCreateSuccess?: (hotel: IHotelRoom[]) => void;
  hotelRoomsRequired?: HotelRoomsRequired[];
  title: string;
}

export const CreateHotelRoomModal = ({
  handleClose,
  handleRoomCreateSuccess,
  open,
  options,
  hotel_id,
  hotelRoomsRequired = [],
  title,
}: Props) => {
  const initialValues: HotelRoom = {
    hotel_id: hotel_id!,
    hotel_rooms_required: [
      {
        floor_no: undefined as number | undefined,
        room_nos: {
          standard: "" as any | undefined,
          deluxe: "" as any | undefined,
          suite: "" as any | undefined,
        },
      },
    ],
  };

  const { rooms } = useFetchHotelDetail(hotel_id);
  const [roomNumbers, setRoomNumbers] = useState<any>([]);

  const baseArray = rooms?.map((i: any) => i.room_no);

  const [roomType, setRoomType] = useState<any>([]);
  const [duplicateRoom, setDuplicateRoom] = useState(false);

  useEffect(() => {
    const fetchAllHotelType = async () => {
      const res = await getAllHotelRoomType();
      setRoomType(res?.hotelroomtype);
    };
    fetchAllHotelType();
  }, []);

  const handleCreateRoom = async (
    hotelroom: Omit<IHotelRoomResponse, "_id" | "room_type">[]
  ) => {
    const payload = {
      hotelroom,
    };
    const response = await createHotelRoom(payload);

    if (response.success === true) {
      message.success("Created Successfully");
      const hotelRooms = (
        (response?.hotelroom || []) as IHotelRoomResponse[]
      ).map((item) => item);
      // .map((item) => ({
      //   ...item,
      //   // hotel_id: {
      //   //   _id: item.hotel_id,
      //   //   hotel_name: "",
      //   // },
      // }));

      if (handleRoomCreateSuccess) handleRoomCreateSuccess(hotelRooms);

      return hotelRooms;
    }
  };

  const getCheckedHotelRooms = (
    hotel_id: string,
    hotel_rooms_required: HotelRoomsRequired[]
  ) => {
    const checkListedRooms: Omit<IHotelRoomResponse, "_id" | "room_type">[] =
      [];
    hotel_rooms_required.forEach((item) => {
      const { floor_no, room_nos } = item;
      room_nos
        .standard!.split(",")
        .map((room) => room.trim())
        .forEach((room: any) => {
          if (/-/.test(room)) {
            const [start, end] = room
              .split("-")
              .map((item: any) => Number(item.trim()));
            if (start && end) {
              for (let i = start; i <= end; i++) {
                checkListedRooms.push({
                  hotel_id,
                  floor_no: floor_no!,
                  room_no: i,
                  hospitality_checklist,
                  room_type_id: roomType?.filter(
                    (items: any) => items.room_type == "Standard"
                  )[0]._id,
                });
              }
            }
          } else {
            const roomNumber = Number(room == 0 ? undefined : room);
            if (!Number.isNaN(roomNumber))
              checkListedRooms.push({
                hotel_id,
                floor_no: floor_no!,
                room_no: roomNumber,
                hospitality_checklist,
                room_type_id: roomType?.filter(
                  (items: any) => items.room_type == "Standard"
                )[0]._id,
              });
          }
        });
    });

    /*Deluxe */

    hotel_rooms_required.forEach((item) => {
      const { floor_no, room_nos } = item;
      room_nos
        .deluxe!.split(",")
        .map((room: any) => room.trim())
        .forEach((room) => {
          if (/-/.test(room)) {
            const [start, end] = room
              .split("-")
              .map((item: any) => Number(item.trim()));
            if (start && end) {
              for (let i = start; i <= end; i++) {
                checkListedRooms.push({
                  hotel_id,
                  floor_no: floor_no!,
                  room_no: i,
                  hospitality_checklist,
                  room_type_id: roomType?.filter(
                    (items: any) => items.room_type == "Deluxe"
                  )[0]._id,
                });
              }
            }
          } else {
            const roomNumber = Number(room == 0 ? undefined : room);
            if (!Number.isNaN(roomNumber))
              checkListedRooms.push({
                hotel_id,
                floor_no: floor_no!,
                room_no: roomNumber,
                hospitality_checklist,
                room_type_id: roomType?.filter(
                  (items: any) => items.room_type == "Deluxe"
                )[0]._id,
              });
          }
        });
    });
    /*Suite */

    hotel_rooms_required.forEach((item) => {
      const { floor_no, room_nos } = item;
      room_nos
        .suite!.split(",")
        .map((room) => room.trim())
        .forEach((room: any) => {
          if (/-/.test(room)) {
            const [start, end] = room
              .split("-")
              .map((item: any) => Number(item.trim()));
            if (start && end) {
              for (let i = start; i <= end; i++) {
                checkListedRooms.push({
                  hotel_id,
                  floor_no: floor_no!,
                  room_no: i,
                  hospitality_checklist,
                  room_type_id: roomType?.filter(
                    (items: any) => items.room_type == "Suite"
                  )[0]._id,
                });
              }
            }
          } else {
            const roomNumber = Number(room == 0 ? undefined : room);
            if (!Number.isNaN(roomNumber))
              checkListedRooms.push({
                hotel_id,
                floor_no: floor_no!,
                room_no: roomNumber,
                hospitality_checklist,
                room_type_id: roomType?.filter(
                  (items: any) => items.room_type == "Suite"
                )[0]._id,
              });
          }
        });
    });
    return checkListedRooms;
  };

  const handleSave = async (values: HotelRoom) => {
    const checkListedRooms = getCheckedHotelRooms(
      values.hotel_id,
      values.hotel_rooms_required
    );

    
    const roomValidator = values?.hotel_rooms_required.map(
      (items: any) =>
        roomRegex.test(items.room_nos.deluxe) !== true &&
        roomRegex.test(items.room_nos.suite) !== true &&
        roomRegex.test(items.room_nos.standard) !== true
    );

   

    const roomValidation = values?.hotel_rooms_required.filter(
      (items) =>
        items.room_nos.deluxe !== "" ||
        items.room_nos.suite !== "" ||
        items.room_nos.standard !== ""
    );

    const checkArray = checkListedRooms.map((items) => items.room_no);

    const filteredArray = (baseArray: any, checkArray: any) => {
      const filter = baseArray.filter((items: any) => {
        if (checkArray.indexOf(items) === -1) {
          message.error("Room Already Exists");
          return false;
        }
        return true;
      });
      return filter;
    };

    if (Object.keys(roomValidation).length !== 0) {
      if (filteredArray(baseArray, checkArray).length == 0) {
        if (duplicateRoom) {
          message.error("Same room number is not allowed");
          return;
        }

        handleCreateRoom(checkListedRooms).then(handleClose);
      }
    } else {
      message.error("Either Room type is required");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({
        errors,
        handleSubmit,
        setFieldValue,
        setValues,
        touched,
        values,
      }) => {
        const valuesExtractor = values?.hotel_rooms_required.map(
          (items: any) => items.room_nos
        );

        function unwind(arr: any) {
          let emptyArr = [];

          for (let i = 0; i < arr.length; i++) {
            emptyArr.push(arr[i]);
          }

          let standard = emptyArr
            .map((i) => i.standard.split(","))
            .flat()
            .filter((e) => e);
          let deluxe = emptyArr
            .map((i) => i.deluxe.split(","))
            .flat()
            .filter((e) => e);
          let suite = emptyArr
            .map((i) => i.suite.split(","))
            .flat()
            .filter((e) => e);

          let concatedArray = standard.concat(deluxe, suite);

          let whiteSpace = concatedArray.filter((e) => e);

          let findDuplicate = whiteSpace.filter(
            (item, index) => whiteSpace.indexOf(item) !== index
          );

          if (findDuplicate.length) {
            setDuplicateRoom(true);
          } else {
            setDuplicateRoom(false);
            return undefined;
          }

          return concatedArray;

          // let c = b.replaceAll(" ", "").split(",");
        }

        unwind(valuesExtractor);

        return (
          <Modal
            open={open}
            centered
            onOk={() => handleSubmit()}
            onCancel={handleClose}
            title={title}
          >
            <div className="mb-3">
              <Label required text="Hotel" />
              <Select
                disabled={hotel_id !== undefined}
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                placeholder="Select Hotel"
                value={values.hotel_id}
                onChange={(id) => setFieldValue("hotel_id", id)}
              >
                {options.map((item) => (
                  <Select.Option key={item._id} value={item._id}>
                    {item.hotel_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <FieldArray name="hotel_rooms_required">
              {({ push, remove }) => {
                return (
                  <React.Fragment>
                    {values.hotel_rooms_required.map((item, index) => {
                      return (
                        <div className="relative py-2" key={index}>
                          <ShouldRender check={index > 0}>
                            <MinusCircleOutlined
                              className="absolute right-0"
                              onClick={() => remove(index)}
                              rev={undefined}
                            />
                          </ShouldRender>
                          <div className="mb-3">
                            <Label required text="Floor" />
                            <Input
                              type="number"
                              min="1"
                              value={item?.floor_no}
                              placeholder="Enter Floor Number"
                              onChange={(e) => {
                                const value = e.target.value;

                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].floor_no = +value;
                                setValues(updatedValues);
                              }}
                            />
                            {(errors as FormErrors)?.hotel_rooms_required?.[
                              index
                            ]?.floor_no &&
                              touched?.hotel_rooms_required?.[index]
                                ?.floor_no && (
                                <div className="my-1 text-xs text-red-500">
                                  {
                                    (errors as FormErrors).hotel_rooms_required[
                                      index
                                    ].floor_no
                                  }
                                </div>
                              )}
                          </div>
                          <div className="mb-3">
                            <Label text="Standard" />
                            <Input
                              placeholder="Enter Room Number i.e. 1,2,3 and so on"
                              value={item?.room_nos?.standard}
                              onChange={(e) => {
                                const value = e.target.value;

                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].room_nos.standard = value;

                                setValues(updatedValues);
                              }}
                            />
                            {(errors as FormErrors)?.hotel_rooms_required?.[
                              index
                            ]?.room_nos &&
                              touched?.hotel_rooms_required?.[index]?.room_nos
                                ?.standard &&
                              roomRegex.test(roomNumbers) && (
                                <div className="my-1 text-xs text-red-500">
                                  {
                                    (errors as FormErrors).hotel_rooms_required[
                                      index
                                    ].room_nos.standard
                                  }
                                </div>
                              )}
                          </div>

                          {/*Deluxe */}

                          <div className="mb-3">
                            <Label text="Deluxe" />
                            <Input
                              placeholder="Enter Room Number i.e. 1,2,3 and so on"
                              value={item?.room_nos?.deluxe}
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].room_nos.deluxe = value;
                                setValues(updatedValues);
                              }}
                            />
                          </div>

                          {/*Suite */}
                          <div className="mb-3">
                            <Label text="Suite" />
                            <Input
                              placeholder="Enter Room Number i.e. 1,2,3 and so on"
                              value={item?.room_nos?.suite}
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].room_nos.suite = value;
                                setValues(updatedValues);
                              }}
                            />
                          </div>
                          <Button
                            block
                            onClick={() =>
                              push({
                                floor_no: undefined,
                                room_nos: {
                                  standard: "",
                                  deluxe: "",
                                  suite: "",
                                },
                              })
                            }
                          >
                            Add Floor
                          </Button>
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            </FieldArray>
          </Modal>
        );
      }}
    </Formik>
  );
};
