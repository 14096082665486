import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  message,
} from "antd";
import { getAllVendorCar } from "../../../../../api-core/Vendor";
import { ShouldRender } from "src/components/ShouldRender";
import AddCars from "../../tables/Cars/AddCars";
import { updateSingleEvent } from "src/api-core/Events";
import { clonseJSON } from "src/utils/json";

const Cars = ({ event, update, loading, events }) => {
  const [open, setOpen] = useState(false);
  const [recordIndex, setRecordIndex] = useState(-1);

  const { cars = [] } = event || {};

  const handleEdit = (index) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const dataSource = event?.cars?.map((items) => ({
    key: items._id,
    owner_name: items.owner_name,
    car_reg: items.car_reg,
    car_model: items.car_model,
    car_number: items.car_number,
    car_type: items.car_type,
    driver_name: items.driver_name,
    driver_mobile: items.driver_mobile,
    vendor_id: items.vendor_id,
  }));

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterToolTip: false,
    },

    {
      title: "Owner Name.",
      dataIndex: "owner_name",
      key: "owner_name",
    },
    {
      title: "Car Reg.",
      dataIndex: "car_reg",
      key: "car_reg",
    },
    {
      title: "Car Model.",
      dataIndex: "car_model",
      key: "car_model",
    },

    {
      title: "Car No.",
      dataIndex: "car_number",
      key: "car_number",
    },
    {
      title: "Car Type",
      dataIndex: "car_type",
      key: "car_type",
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "driver_name",
    },
    {
      title: "Driver Mobile",
      dataIndex: "driver_mobile",
      key: "driver_mobile",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const index = dataSource?.findIndex(
          (item) => JSON.stringify(item) == JSON.stringify(record)
        );

        return (
          <div className="flex gap-2">
            <span>
              <Typography.Link
                style={{ marginRight: 8 }}
                onClick={() => handleEdit(index)}
              >
                Edit
              </Typography.Link>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(index)}
              >
                <Typography.Link>Delete</Typography.Link>
              </Popconfirm>
            </span>
          </div>
        );
      },
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUpdate = (data) => {
    if (events) {
      const {
        _id,
        client_id,
        event_type,
        event_title,
        event_start_date,
        coordinator_ids,
        event_descp,
      } = events;

      const payload = {
        client_id,
        coordinator_ids,
        event_descp,
        event_type,
        event_title,
        event_start_date,
        ...data,
      };

      return updateSingleEvent(_id, payload).then(update).then(handleClose);
    }
  };

  const handleSave = (values) => {
    let updateCarsVendor = clonseJSON(cars);

    if (recordIndex === -1) {
      updateCarsVendor.push(values);
    } else {
      updateCarsVendor[recordIndex] = values;
    }

    const data = {
      event_vendors: {
        vendors: event?.vendors,
        cars: updateCarsVendor,
      },
    };

    handleUpdate(data).then(update).then(handleClose);
  };

  const handleDelete = (index) => {
    const updatedCarsVendor = cars.splice(index, 1);

    const data = {
      event_vendors: {
        vendors: event?.vendors,
        cars: cars,
      },
    };

    handleUpdate(data).then(update);
  };

  return (
    <div className="w-full overflow-auto">
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>

      <div className="overflow-y-hidden w-full">
        <Input.Search
          size="large"
          placeholder="search here..."
          style={{ marginBottom: 8, width: 280 }}
        />
        <Table
          // rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          loading={false}
          size="large"
          scroll
        />
        <ShouldRender check={open}>
          <AddCars
            open={open}
            data={recordIndex === -1 ? [] : dataSource[recordIndex]}
            title={recordIndex === -1 ? "Add Cars" : "Edit Cars"}
            handleClose={handleClose}
            handleSave={handleSave}
            event={event}
          />
        </ShouldRender>
      </div>
    </div>
  );
};

export default Cars;
