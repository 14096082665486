import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getsingleOfferBanner,
  updateOfferBanner,
} from "../../../api-core/OfferBanner";
import { getAllSubEvents } from "../../../api-core/Events";
import { formatDate } from "../../../utils/helper";
import { message } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { viewCurrentDates } from "../../../utils/helper";
import { eventType } from "src/constants";

const UpdateOfferBanner = () => {
  const { id } = useParams();
  const [bannerImgSelected, setBannerImgSelected] = useState("");
  const [preview, setPreview] = useState("");
  const [previousBanner, setPreviousBanner] = useState("");
  // const [date] = useState(new Date().toISOString().slice(0, 10));
  const [offerEndDate, setOfferEndDate] = useState("");

  const navigate = useNavigate();

  const [bannerImg, setBannerImg] = useState("");
  const [inputs, setInputs] = useState({
    event_type: "",
    banner_title: "",
    banner_descp: "",
    offer_starts: "",
    offer_ends: "",
    price: "",
    discount: "",
  });

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
    if (e.target.files[0] && e.target.files[0].size > 2 * 1024 * 1024) {
      e.target.value = null;
      return message.error("File exceeds the limit of 2MB");
    }
    setBannerImg(e.target.files[0]);

    // if (e.target.files[0]) {
    //   setBannerImg(e.target.files[0]);
    // }

    if (bannerImgSelected) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setPreviousBanner("");
    }
  };

  useEffect(() => {
    const fetchSingleOffer = async () => {
      const res = await getsingleOfferBanner(id);

      setInputs({
        event_type: res?.offerbanner[0].event_type,
        banner_title: res?.offerbanner[0].banner_title,
        banner_descp: res?.offerbanner[0].banner_descp,
        offer_starts: formatDate(
          res?.offerbanner[0].offer_starts,
          "yyyy-MM-DD"
        ),
        offer_ends: formatDate(res?.offerbanner[0].offer_ends, "yyyy-MM-DD"),
        price: res?.offerbanner[0].price,
        discount: res?.offerbanner[0].discount,
      });
      setBannerImgSelected(res?.offerbanner[0].filename);
      setPreviousBanner(res?.offerbanner[0].banner_img);
    };
    fetchSingleOffer();
  }, [id]);

  const formik = useFormik({
    initialValues: inputs,
    // validationSchema: Yup.object({
    //   event_type: Yup.string().required("Event Name is required"),
    //   banner_title: Yup.string().required("Banner title is required"),
    //   banner_descp: Yup.string().required("Banner Description is required"),
    //   offer_starts: Yup.string().required("start offer date is required"),
    //   offer_ends: Yup.string().required("end offer date is required"),
    //   price: Yup.string().required("Event Name is required"),
    //   discount: Yup.string().required("Discount is required"),
    // }),
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append(
        "banner_img",
        bannerImg !== "" ? bannerImg : bannerImgSelected
      );
      formData.append("event_type", inputs.event_type);
      formData.append("banner_title", inputs.banner_title);
      formData.append("banner_descp", inputs.banner_descp);
      formData.append("offer_starts", inputs.offer_starts);
      formData.append("offer_ends", inputs.offer_ends);
      formData.append("price", inputs.price);
      formData.append("discount", inputs.discount);

      if (inputs) {
        const res = await updateOfferBanner(formData, id);

        if (res.success == true) {
          message.success("Updated Successfully");
          setInputs({
            event_type: "",
            banner_title: "",
            banner_descp: "",
            offer_starts: "",
            offer_ends: "",
            price: "",
            discount: "",
          });
          setBannerImg("");
          navigate("/admin/listofferbanner");
        }
        if (res.response.data) {
          for (let key in res.response.data) {
            message.error(res.response.data[key]);
          }
        }
      }
    },
  });

  useEffect(() => {
    setOfferEndDate(inputs.offer_starts);
  }, [inputs.offer_starts]);

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Offer Banner</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Type
            </label>
            <select
              value={inputs.event_type}
              name="event_type"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Select Event</option>
              {eventType.map((item) => (
                <option
                  value={item.value}
                  key={item._id}
                  selected={item.value && item.value == inputs.event_type}
                >
                  {item.value}
                </option>
              ))}
            </select>
            {inputs.event_type == "" && (
              <span className="text-red-500">Event must not be Empty</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Banner Title
            </label>
            <input
              type="text"
              name="banner_title"
              value={inputs.banner_title}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner title"
            />
            {inputs.banner_title == "" && (
              <span className="text-red-500">
                Banner Title must not be Empty
              </span>
            )}
            {inputs.banner_title.length < 3 && (
              <span className="text-red-500">
                must contains atleast 3 chars
              </span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Banner Description
            </label>
            <textarea
              type="text"
              name="banner_descp"
              value={inputs.banner_descp}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner desc"
            />
            {inputs.banner_descp == "" && (
              <span className="text-red-500">
                Banner Description must not be Empty
              </span>
            )}
            {inputs.banner_descp.length < 3 && (
              <span className="text-red-500">
                must contains atleast 3 chars
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Offer Start
            </label>
            <input
              type="date"
              name="offer_starts"
              value={inputs.offer_starts}
              // min={viewCurrentDates}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter offer date"
              required
            />
            {inputs.offer_starts == "" && (
              <span className="text-red-500">Date must not be Empty</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Offer End
            </label>
            <input
              type="date"
              name="offer_ends"
              value={inputs.offer_ends}
              min={inputs.offer_starts}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter offer end date"
              required
            />
            {inputs.offer_ends == "" && (
              <span className="text-red-500">Date must not be Empty</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Price
            </label>
            <input
              type="number"
              name="price"
              value={inputs.price}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter price"
              required
            />
            {inputs.price == "" && (
              <span className="text-red-500">Price must not be Empty</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Discount
            </label>
            <input
              type="text"
              name="discount"
              value={inputs.discount}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              placeholder="enter discount"
            />
            {inputs.discount == "" && (
              <span className="text-red-500">Discount must not be Empty</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <div>
              {previousBanner !== "" ? (
                <img
                  src={previousBanner}
                  className="h-20 w-20"
                  alt="previous"
                />
              ) : (
                <img src={preview} className="h-20 w-20" alt="preview" />
              )}
            </div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Banner Image
            </label>
            <input
              type="file"
              name="banner_img"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Banner Image"
            />
          </div>
        </div>
        <Link to={"/admin/listofferbanner"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button
            type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            Cancel
          </button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateOfferBanner);
