import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import EditLostAndFound, { LostAndFound } from "./EditLostAndFound";
import { ShouldRender } from "src/components/ShouldRender";
import {
  createLostAndFound,
  deleteLostAndFound,
  updateLostAndFound,
} from "src/api-core/Ghms";
import { IEvent, IGuestList, ILostAndFound } from "src/types";

interface Props {
  event: IEvent | undefined;
  guests: IGuestList[] | undefined;
  lostAndFound: ILostAndFound[] | undefined;
  loading: boolean;
  update: () => Promise<void>;
}

export const LostAndFoundTable = ({
  event,
  guests = [],
  lostAndFound = [],
  loading,
  update,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleSave = async (values: LostAndFound) => {
    if (recordIndex === -1) {
      if (event) {
        const { client_id, _id: event_id } = event;
        const payload = {
          client_id,
          event_id,
          ...values,
        };
        createLostAndFound(payload).then(update).then(handleClose);
      }
    } else {
      const recordDetail = lostAndFound[recordIndex];
      const {
        item_identification,
        item_name,
        lost_place,
        found_by,
        found_place,
        deliver_type,
        guest_id,
      } = values;
      const { client_id, event_id, _id } = recordDetail;
      const payload = {
        client_id,
        event_id,
        guest_id,
        item_identification,
        item_name,
        lost_place,
        found_by,
        found_place,
        deliver_type,
      };
      updateLostAndFound(payload, _id).then(update).then(handleClose);
    }
  };

  const handleDelete = (id: string) => {
    const ids = [id];
    deleteLostAndFound(ids).then(update);
  };

  const defaultColumns = [
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      render: (_: any, record: LostAndFound) => {
        const guestName = guests.find(({_id}) => _id === record.guest_id);
        return guestName?.guest_name || "NA";
      },
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
    },
    {
      title: "Item Identification",
      dataIndex: "item_identification",
      key: "item_identification",
    },
    {
      title: "Lost Place",
      dataIndex: "lost_place",
      key: "lost_place",
    },
    {
      title: "Found Place",
      dataIndex: "found_place",
      key: "found_place",
    },
    {
      title: "Found By",
      dataIndex: "found_by",
      key: "found_by",
    },
    {
      title: "Deliver Type",
      dataIndex: "deliver_type",
      key: "deliver_type",
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.item_name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.found_place)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.lost_place)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.found_by)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.found_place)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.guest_name)
                .toLowerCase()
                .includes(value.toLowerCase())
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: ILostAndFound) => {
        const index = lostAndFound.findIndex((item) => item._id === record._id);
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record._id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        columns={defaultColumns}
        dataSource={lostAndFound}
        key="_id"
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditLostAndFound
          open={open}
          data={lostAndFound[recordIndex]}
          guests={guests}
          title={
            recordIndex === -1 ? "Add Lost And Found" : "Edit Lost And Found"
          }
          handleClose={handleClose}
          handleSave={handleSave}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
